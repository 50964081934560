// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Rails related
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"

Rails.start()
Turbolinks.start()

import '@fortawesome/fontawesome-pro/js/all';


// Polyfills
import "inert-polyfill"
import 'element-matches-polyfill'
import 'dwnld-attr-polyfill'
import 'parent-node-prepend-polyfill'
import 'matchmedia-polyfill'
import ObjectAssign from 'es6-object-assign'

ObjectAssign.polyfill();

// Website specific
import DisclosureWidget from "disclosure-widget"
import LoginPanel from "login-panel"
import Navigation from "navigation"
import ScrollbarWidth from "scrollbar-width"
import AsyncItemsFetcher from "async-items-fetcher"
import ARIAaccordion from 'aria-accordion'
import ARIAtabs from "aria-tabs"
import ARIAtables from "aria-tables"
import ARIAslider from "aria-slider"
import GuidelineOrderFormDownload from "guideline_order_form_download"
import EventRegistrationSubmit from "event_registration_submit"
import "din_order_form"

import "@oddcamp/cocoon-vanilla-js"
import HesGallery from "hes-gallery"

document.addEventListener("turbolinks:load", function() {
  HesGallery.init()
  EventRegistrationSubmit.start()
  GuidelineOrderFormDownload.start()
  DisclosureWidget.start()
  LoginPanel.start()
  Navigation.start()
  AsyncItemsFetcher.start()
  ARIAtabs.start()
  ARIAaccordion.start()
  ARIAtables.start()
  ARIAslider.start()
  ScrollbarWidth.start()
});
