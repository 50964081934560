"use strict";

var DinOrderForm = {
  start: function() {
    this.form = document.getElementById("din_order_form")
    if (this.form == undefined) {
      return
    }

    let _this = this;

    // this.handleBillingAddress()

    this.fee_container = document.getElementById("DinOrderFee");
    this.terms_of_service = document.getElementById("ToSCheckbox");
    this.license = document.getElementById("LicenseSelect");
    this.sponsor = document.getElementById("SponsorCheckbox");
    this.btn = document.getElementById("DinOrderSubmit");
    this.fees = JSON.parse(this.fee_container.dataset.fees);

    if (this.fee_container != undefined) {
      this.terms_of_service.addEventListener('change', function() { _this.updateForm(_this) }, false );
      this.license.addEventListener('change', function() { _this.updateForm(_this) }, false );
      this.sponsor.addEventListener('change', function() { _this.updateForm(_this) }, false );
    }
  },

  handleBillingAddress: function() {
    const _this = this
    const hasBillingAddressLabel = this.form.querySelector("#has_billing_address_label")

    hasBillingAddressLabel.addEventListener("click", function() {
      _this.showHideBillingAddressFields()
    })

    this.showHideBillingAddressFields()
  },

  showHideBillingAddressFields: function() {
    const hasBillingAddressCheckbox = this.form.querySelector("#din_order_has_billing_address")
    const billingAddressContainer = this.form.querySelector("#billing_address")
    const billingAddressFields = billingAddressContainer.querySelectorAll("input, select")

    if(hasBillingAddressCheckbox.checked) {
      billingAddressContainer.removeAttribute("hidden")
      billingAddressFields.forEach(el => el.removeAttribute("disabled"))
    } else {
      billingAddressContainer.setAttribute("hidden", "")
      billingAddressFields.forEach(el => el.setAttribute("disabled", ""))
    }
  },

  updateForm: function updateForm(_this) {
    _this.updatePrice();
    _this.handleBtnState();
  },

  updatePrice: function updatePrice() {
    var license = this.license.value;

    if(this.fees[license] == undefined) {
      this.fee_container.innerHTML = '';
      return;
    }

    var price = this.fees[license]['price'];
    var formatted_price = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR' }).format(price);

    var html = '<div class="price">' + formatted_price + '</div>';

    var discount = 0.00;

    if (this.sponsor.checked) {
      discount = this.fees[license]['discount'];
    }

    var formatted_discount = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR' }).format(discount*-1);
    var formatted_total = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR' }).format(price - discount);

    html = html + '<div class="discount">' + formatted_discount + '</div><div class="total">' + formatted_total + '</div>';
    this.fee_container.innerHTML = html;
  },

  handleBtnState: function handleBtnState() {
    let tos_accepted = this.terms_of_service.checked;
    let product_chosen = this.fees[this.license.value] != undefined;

    if(product_chosen && tos_accepted) {
      this.btn.disabled = false;
    }
    else {
      this.btn.disabled = true;
    }
  }
}

document.addEventListener("turbolinks:load", function() {
  DinOrderForm.start()
})
